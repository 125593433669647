









import { Component, Vue, Prop } from 'vue-property-decorator'

import Information from '@/components/implementation/information/Information.vue'

import { InformationResource } from '@/models/implementations/information/InformationResource'

@Component({
  components: {
    Information,
  }
})
export default class ImplementationInformation extends Vue {

  @Prop()
  private information!: InformationResource[]

}
