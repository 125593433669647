

















import { Component, Vue, Prop } from 'vue-property-decorator'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import QuestionListItem from '@/components/implementation/question/QuestionListItem.vue'

import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationEntityProposalResource } from '@/models/implementations/proposal/ImplementationEntityProposalResource'
import { QuestionResource } from '@/models/implementations/questions/QuestionResource'

@Component({
  components: {
    SmallLoader,
    QuestionListItem,
  }
})
export default class ImplementationEntityQuestions extends Vue {

  @Prop()
  private readonly proposal!: ImplementationEntityProposalResource

  @Prop()
  private readonly entity!: EntityResource

  @Prop()
  private questions!: QuestionResource[]

  @Prop()
  private fetchingQuestion!: boolean

  private refreshEntity(): void {
    this.entity.refresh()
  }
}
