







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ScoreCard extends Vue {

  @Prop()
  private totalCount!: number

  @Prop()
  private finishedCount!: number

  private get statusClass(): string {
    if (this.totalCount > 0 && this.finishedCount >= this.totalCount) {
      return 'finished'
    } else if (this.finishedCount > 0) {
      return 'busy'
    }
    return 'notstarted'
  }

}
