











































import { userModule } from '@/store'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import NoItems from '@/components/partials/NoItems.vue'
import AddComment from '@/components/comments/AddComment.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { CommentResource } from '@/models/comments/CommentResource'
import { ProposalResource } from '@/models/proposals/ProposalResource'
import { ProjectUserResource } from '@/models/projects/ProjectUserResource'
import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationEntityProposalResource } from '@/models/implementations/proposal/ImplementationEntityProposalResource'
import { UserResource } from '@/models/users/UserResource'

// component
@Component({
  components: {
    NoItems,
    AddComment,
    SmallLoader
  },
})
export default class ImplementationProposalComments extends Vue {
  @Prop()
  private readonly commentFilter!: string | null

  @Prop()
  private readonly proposal!: ImplementationEntityProposalResource

  @Prop()
  private readonly entity!: EntityResource

  @Prop({ default: true })
  private readonly readonly!: boolean

  private users: UserResource[] = []

  @Watch('proposal.id')
  private onProposalChange(proposalId: number, oldProposalId: number): void {
    if (proposalId !== oldProposalId) {
      this.getComments()
    }
  }

  private get canComment(): boolean {
    // return canPerformAction(this.proposal.permissions, 'can_comment') && !this.readonly
    return true
  }

  private get filteredComments(): CommentResource[] {
    if (this.commentFilter) {
      return this.proposal.comments.items.filter((c) => {
        if (this.commentFilter === 'resolved') {
          return c.status === 'resolved' || c.status === 'declined'
        }
        return c.status === this.commentFilter
      })
    }

    return this.proposal.comments.items
  }

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private created(): void {
    this.getUsers()
    this.getComments()
  }

  private async getUsers(): Promise<void> {
    const data = await this.entity.getAllUsers()
    this.users = data.data
  }

  private deleteComment(id: number): void {
    this.proposal.comments.deleteComment(id)
  }

  private async getComments(): Promise<void> {
    this.proposal.comments.loading = true
    try {
      await this.proposal.comments.get()
      this.scrollToBottom()
    } finally {
      this.proposal.comments.loading = false
    }
  }

  private async addComment(message: string): Promise<void> {
    this.proposal.comments.loading = true
    try {
      await this.proposal.comments.post(message)
      this.$emit('refresh')
      this.scrollToBottom()
    } finally {
      this.proposal.comments.loading = false
    }
  }

  private scrollToBottom(): void {
    const wrapper = this.$refs.wrapper as HTMLElement
    if (!wrapper) return
    wrapper.scrollTop = wrapper.scrollHeight
  }
}
