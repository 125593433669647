










































import Comments from './partials/Comments.vue'
import Questions from './partials/Questions.vue'
import Tabs from '@/components/widgets/Tabs.vue'
import ScoreCard from '@/components/misc/Scorecard.vue'
import ProposalInformation from './partials/Information.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { QuestionResource } from '@/models/implementations/questions/QuestionResource'
import { InformationResource } from '@/models/implementations/information/InformationResource'
import { ImplementationEntityProposalResource } from '@/models/implementations/proposal/ImplementationEntityProposalResource'

@Component({
  components: {
    Tabs,
    Comments,
    ScoreCard,
    SmallLoader,
    Questions,
    ProposalInformation
  },
})
export default class ImplementationProposal extends Vue {
  @Prop()
  private proposal?: ImplementationEntityProposalResource

  @Prop()
  private entity?: EntityResource

  private currentPage: string = 'checklist'

  private questions: QuestionResource[] = []
  private fetchingQuestions: boolean = false

  @Watch('proposal.id')
  private onProposalChange(proposalId: number | undefined, oldProposalId: number | undefined): void {
    if (proposalId !== oldProposalId) {
      this.getQuestions()
      this.currentPage = 'checklist'
    }
  }

  @Watch('entity.id')
  private onEntityChange(entityId: number| undefined, oldEntityId: number | undefined): void {
    if (entityId !== oldEntityId) {
      this.getQuestions()
      this.currentPage = 'checklist'
    }
  }

  private mounted() {
    this.getQuestions()
  }

  private async getQuestions(): Promise<void> {
    this.questions = []
    this.fetchingQuestions = true
    try {
      const data = await this.proposal?.getQuestions()
      if (data) {
        this.questions = data.data
      }
    } finally {
      this.fetchingQuestions = false
    }
  }

  private goToPage(page: string): void {
    this.currentPage = page
  }
}
